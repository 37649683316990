<template>
  <base-dialog width="400" title="Configuración" icon="mdi-cog-outline">
    <v-row align="center">
      <v-col cols="7">
        Hojas de consentimientos
      </v-col>
      <v-col cols="5">
        <ConsentForm :service="service" @update="$emit('update')" />
      </v-col>
      <v-col cols="7">
        Artistas
      </v-col>
      <v-col cols="5">
        <Tattooers :service="service" @update="$emit('update')" />
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
export default {
  components: {
    ConsentForm: () => import("./ConsentForm"),
    Tattooers: () => import("./Tattooers"),
  },
  props: ["service"],
};
</script>
